import React from "react";
import { Alert, Button, Col, Image, Row } from "react-bootstrap";
import FrontEndImg from "../assets/courses/img-3.jpg";
import CourseDurationImg from "../assets/icons/synchronous.png";
import CourseCertiImg from "../assets/icons/certificate1.png";
import AssignmentImg from "../assets/icons/qa.png";
import HTML from "../assets/icons/html-5.png";
import CSS from "../assets/icons/css-3.png";
import JavaScript from "../assets/icons/js.png";
import Bootstrap from "../assets/icons/bootstrap.png";
import ReactJS from "../assets/icons/react.png";
import Banner from "../assets/courses/banner.jpg";

const CourseDetails = () => {
  return (
    <>
      <h4 className="pb-1 pt-3">Course Details</h4>
      <Row className="mx-0 pt-0">
        <Col className="ps-0 pe-1">
          <Alert variant="light" className="shadow-sm p-0">
            {/* <Image src={Banner} className="img-fluid rounded-top-2" /> */}
            <div className="details px-3 pt-5 pb-2">
              <h5 className="mb-0 text-warning">
                Learn Complete Front-End Web Development Course
              </h5>
              <p className="course-desc text-white w-75 mb-0">
                Learn Complete Front End Web Development with HTML5, CSS3,
                Bootstrap 5, JavaScript, ECMAScript 6 & React JS
              </p>
            </div>
            <div className="px-3 pt-2">
              <h5>What you'll learn:</h5>
              <ul>
                <li>
                  You will learn complete front-end web development from scratch
                </li>
                <li>You will learn to build Bootstrap 5 Web Applications</li>
                <li>
                  You will learn Complete JavaScript + ECMA Script 6 (ES6) from
                  scratch
                </li>
                <li>
                  You will learn HTML5 & CSS3 from scratch, & build a full
                  working website
                </li>
                <li>You will learn to build Modern websites with React JS</li>
                <li>
                  You will build front-end Reactive Web applications with CRUD
                  operations
                </li>
              </ul>
              <h5>Technologies you will learn:</h5>
              <div className="d-flex gap-5 align-items-center pt-1 pb-3">
                <Image src={HTML} className="tools" />
                <Image src={CSS} className="tools" />
                <Image src={JavaScript} className="tools" />
                <Image src={Bootstrap} className="tools" />
                <Image src={ReactJS} className="tools" />
              </div>
              <h5>Course Curriculum:</h5>
              <Row>
                <Col>
                  <ul>
                    <li>Introduction to HTML</li>
                    <li>Text Formatting in HTML</li>
                    <li>HTML Essentials</li>
                    <li>Images in HTML</li>
                    <li>Multimedia in HTML</li>
                    <li>Content in HTML</li>
                    <li>Form Elements in HTML</li>
                    <li>Tables in HTML</li>
                    <li>Getting started with CSS</li>
                    <li>CSS Essentials</li>
                    <li>Understanding Box Model in CSS</li>
                    <li>Typography in CSS</li>
                    <li>Layouts in CSS</li>
                    <li>Flexbox and Grid in CSS</li>
                    <li>Advanced CSS Selections</li>
                    <li>Building the Website</li>
                    <li>Responsive Web Design</li>
                    <li>JavaScript - Getting Started</li>
                    <li>JavaScript - Variables, Scopes, and more</li>
                    <li>JavaScript - Working with Arrays</li>
                    <li>JavaScript - Working with Objects</li>
                    <li>JavaScript - Functions and Methods</li>
                  </ul>
                </Col>
                <Col>
                  <ul>
                    <li>JavaScript - String Outputs</li>
                    <li>JavaScript - Document Object Model and Events</li>
                    <li>Bootstrap 5 - Getting Started</li>
                    <li>Bootstrap 5 - Layouts</li>
                    <li>Bootstrap 5 - Contents</li>
                    <li>Bootstrap 5 - Forms</li>
                    <li>Bootstrap 5 - Components</li>
                    <li>Bootstrap 5 - Utilities</li>
                    <li>Bootstrap 5 - Helpers</li>
                    <li>BLOG Project</li>
                    <li>Professional Website Project</li>
                    <li>React JS - Getting Started</li>
                    <li>React JS - Introduction to JSX</li>
                    <li>React JS - Components</li>
                    <li>React JS - Import, Export and Modules</li>
                    <li>React JS - Props</li>
                    <li>React JS - useState Hooks</li>
                    <li>React JS - Hooks</li>
                    <li>React JS - Forms</li>
                    <li>React JS - Working with React Routers</li>
                    <li>React JS - Redux Toolkit</li>
                    <li>React JS - Project</li>
                  </ul>
                </Col>
              </Row>
              <h5>Requirements:</h5>
              <ul>
                <li>
                  No programming experience needed - we will teach you
                  everything you need to know
                </li>
                <li>A computer with access to the internet</li>
                <li>No paid software required</li>
                <li>
                  Step-by-step how to get all the software installed and set up
                </li>
              </ul>
              <h5>Job Roles:</h5>
              <Row>
                <Col>
                  <ul>
                    <li>Front-End Developer</li>
                    <li>UI/UX Developer</li>
                    <li>JavaScript Developer</li>
                  </ul>
                </Col>
                <Col>
                  <ul>
                    <li>React Developer</li>
                    <li>Web Designer/Developer</li>
                    <li>Full-Stack Developer (with Front-End Focus)</li>
                  </ul>
                </Col>
              </Row>
            </div>
          </Alert>
        </Col>
        <Col md={4} className="ps-1 pe-0 c-price">
          <Alert variant="light" className="shadow-sm p-0">
            <Image
              src={FrontEndImg}
              className="img-fluid border border-2 rounded-2"
            />
            <div className="border-top p-3">
              <h5 className="d-flex gap-4">
                <span className="text-danger fw-bold">
                  <i className="fa fa-rupee pe-1"></i>6,500
                </span>
                <span className=" text-decoration-line-through">
                  <i className="fa fa-rupee pe-1"></i>10,500
                </span>
              </h5>
              <Button variant="primary" className="w-100 mb-2">
                Enroll now
              </Button>
              <h6 className="mb-1 pt-2">This course includes:</h6>
              <div className="px-2">
                <p className="mb-0">
                  <Image
                    src={CourseDurationImg}
                    className="course_duration pe-1"
                  />{" "}
                  Duration: <span className="fw-semibold">90 Days</span>
                </p>
                <p className="mb-0">
                  <Image src={AssignmentImg} className="course_duration pe-1" />{" "}
                  Weekly assignments
                </p>
                <p className="mb-0">
                  <Image
                    src={CourseCertiImg}
                    className="course_duration pe-1"
                  />{" "}
                  Certificate of completion
                </p>
              </div>
            </div>
          </Alert>
        </Col>
      </Row>
    </>
  );
};

export default CourseDetails;
