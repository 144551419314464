import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import RefundImg from "../assets/refund-policy-banner.jpg";

const Refund = () => {
  return (
    <>
      <Row className="mx-0 px-0 pb-3">
        <Col className="px-0">
          <Image src={RefundImg} className="img-fluid" />
        </Col>
      </Row>
      <Row className="mx-0 px-5">
        <Col>
          <h4>Refund and Course Rescheduling policy</h4>
          <p>
            Thank you for buying our courses. We want to make sure that our
            users have a rewarding experience while they are discovering
            information, assessing, and purchasing our training courses, whether
            it may be for online or classroom training courses.
          </p>
          <p>
            As with any online purchase experience, the below are the terms and
            conditions that govern the Refund Policy. When you buy a training
            course on the VD Talent hub website you agree to our{" "}
            <span className="fw-bold">Privacy Policy and Terms of use</span>{" "}
            policy and the points below.
          </p>
          <h5>CANCELLATION AND REFUNDS: ONLINE CLASSROOM LEARNING</h5>
          <p>
            VD Talent hub, reserves the right to postpone/cancel an event
            because of instructor illness or force-majeure events (like floods,
            earthquakes, political instability, pandemic situation like COVID19,
            etc.)
            <ul>
              <li>
                The refund request will not be accepted if you have attended the
                online classroom training for more than 1 day's session or have
                accessed / downloaded course material from the learning portal.
              </li>
              <li>No refund will be provided on discounted courses.</li>
              <li>
                No refund will be provided for the academic institute tie up
                courses.
              </li>
              <li>
                If the delegate doesn’t turn up on the given schedule then no
                refund will be provided.
              </li>
              <li>In the case of refund, taxes amount will not be refunded.</li>
              <li>
                If due to any technical reason learners are not able to access
                the self-paced videos or class recordings and already attended
                the complete course through instructor led online classes then
                in that case no refund is applicable or a can be provided with a
                voucher for the amount equivalent of the 10% amount paid by the
                learner at the time of enrollment.
              </li>
              <li>
                <span className="fw-bold">Note:</span> All refunds will be
                processed within 30 working days, post review and approval of
                any such request.
              </li>
            </ul>
          </p>
          <h5>CANCELLATION AND REFUNDS: SELF PACED LEARNING</h5>
          <p>
            <ul>
              <li>No refund will be done for Self Paced courses.</li>
              <li>
                No refund will be provided and access will be revoked , If
                anytime found course is been shared with others / multiple users
                or intention of course purchase is to copy the material/ Content
                and account will be blocked at the same time.
              </li>
            </ul>
          </p>
          <h5>CANCELLATION AND REFUNDS: SUBSCRIPTION PLANS</h5>
          <p>
            <ul>
              <li>No refund will be done for Subscription plans.</li>
            </ul>
          </p>
        </Col>
      </Row>
      <Row className="mx-0 py-3"></Row>
    </>
  );
};

export default Refund;
