import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const userLogin = createAsyncThunk("user/userLogin", async (userdet) => {
  const request = await axios.post(
    "http://localhost:8000/api/v1/talent_user_login",
    userdet
  );
  const response = await request.data;
  console.log(response);

  return response;
});

export const userSignup = createAsyncThunk(
  "user/userSignup",
  async (userdet) => {
    const request = await axios.post(
      "http://localhost:8000/api/v1/talent_users",
      userdet
    );
    const response = await request.data;
    console.log(response);

    return response;
  }
);

const userSlice = createSlice({
  name: "users",
  initialState: {
    user: null,
    msg: null,
  },
  reducers: {
    logout: (state, action) => {
      state.user = null;
      state.msg = null;
      localStorage.clear();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userLogin.pending, (state) => {
        state.user = null;
        state.msg = null;
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        if (action.payload.user) {
          state.user = action.payload.user;
          localStorage.setItem("user", JSON.stringify(action.payload.user));
          localStorage.setItem("loggedin", true);
          state.msg = null;
        }
      })
      .addCase(userLogin.rejected, (state, action) => {
        state.user = [];
        state.msg = "Error";
      });
    builder.addCase(userSignup.fulfilled, (state, action) => {
      if (action.payload.user) {
        state.user = null;
        state.msg = action.payload.message;
      }
    });
  },
});

export const { logout } = userSlice.actions;

export default userSlice.reducer;
