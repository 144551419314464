import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  redirect,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import Default from "./pages/Default";
import Internships from "./pages/Internships";
import Services from "./pages/Services";
import Projects from "./pages/Projects";
import Career from "./pages/Career";
import Signin from "./authentication/Signin";
import Signup from "./authentication/Signup";
import Projectupload from "./pages/Projectupload";
import Dashboard from "./pages/Dashboard";
import Userdashboard from "./pages/Userdashboard";
import MyCertificates from "./pages/MyCertificates";
import Newproject from "./pages/Newproject";
import Mycourses from "./pages/Mycourses";
import Myinternships from "./pages/Myinternships";
import Quizz from "./pages/Quizz";
import Myprofile from "./pages/Myprofile";
import Messages from "./pages/Messages";
import Mysubscription from "./pages/Mysubscription";
import { Provider } from "react-redux";
import Store from "./store/Store";
import Contact from "./pages/Contact";
import Aboutus from "./pages/Aboutus";
import Privacy from "./components/Privacy";
import TermsofUse from "./components/TermsofUse";
import Refund from "./components/Refund";
import MyReferrals from "./pages/MyReferrals";
import CourseDetails from "./courses/CourseDetails";
import Shipping from "./components/Shipping";

const root = ReactDOM.createRoot(document.getElementById("root"));
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <Default />,
      },
      {
        path: "Aboutus",
        element: <Aboutus />,
      },
      {
        path: "Services",
        element: <Services />,
      },
      {
        path: "Internships",
        element: <Internships />,
      },
      {
        path: "Projects",
        element: <Projects />,
      },
      {
        path: "Career",
        element: <Career />,
      },
      {
        path: "Contact",
        element: <Contact />,
      },
      {
        path: "Privacy-policy",
        element: <Privacy />,
      },
      {
        path: "Terms-of-use",
        element: <TermsofUse />,
      },
      {
        path: "Refund-policy",
        element: <Refund />,
      },
      {
        path: "Shipping",
        element: <Shipping />,
      },
    ],
  },
  {
    path: "Signin",
    element: <Signin />,
  },
  {
    path: "Signup",
    element: <Signup />,
  },

  {
    path: "User",
    element: <Dashboard />,
    children: [
      {
        path: "/User/Dashboard",
        element: <Userdashboard />,
      },
      {
        path: "/User/AllProjects",
        element: <Projectupload />,
      },
      {
        path: "/User/Mycertificates",
        element: <MyCertificates />,
      },
      {
        path: "/User/Newproject",
        element: <Newproject />,
      },
      {
        path: "/User/Mycourses",
        element: <Mycourses />,
      },
      {
        path: "/User/Myinternships",
        element: <Myinternships />,
      },
      {
        path: "/User/Myreferrals",
        element: <MyReferrals />,
      },
      {
        path: "/User/Quiz",
        element: <Quizz />,
      },
      {
        path: "/User/Myprofile",
        element: <Myprofile />,
      },
      {
        path: "/User/Messages",
        element: <Messages />,
      },
      {
        path: "/User/Mysubscription",
        element: <Mysubscription />,
      },
      {
        path: "/User/Course-details",
        element: <CourseDetails />,
      },
    ],
  },
]);
root.render(
  <React.StrictMode>
    <Provider store={Store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);
