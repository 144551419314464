import React, { useState } from "react";
import {
  Badge,
  Button,
  Col,
  Container,
  Form,
  Image,
  InputGroup,
  Modal,
  Nav,
  NavDropdown,
  Navbar,
  Row,
  Table,
} from "react-bootstrap";
import Logo from "../assets/talenthub.png";
import NotificationIcon from "../assets/icons/notification.png";
import UserIcon from "../assets/icons/user1.png";
import ProfilePic from "../assets/icons/wallet.png";
import { LinkContainer } from "react-router-bootstrap";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../store/UserSlice";

const Dashboard = () => {
  const loggedin = localStorage.getItem("loggedin");
  const navigate = useNavigate();
  const userDet = JSON.parse(localStorage.getItem("user"));
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [transShow, setTransShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleTransClose = () => setTransShow(false);
  const handleTransShow = () => setTransShow(true);
  return (
    <>
      <Navbar expand="lg" className="bg-body-secondary py-0 sticky-top">
        <Container fluid>
          <Navbar.Brand href="#home">
            <Image src={Logo} alt="Talent hub" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto align-items-center">
              <div className="d-flex align-items-center">
                <span>Welcome, </span>
                <h6 className="fw-bold pt-2 px-1">
                  {userDet && userDet[0].user_name}
                </h6>
              </div>
              {/* <div className="d-flex justify-content-between align-items-center px-2">
                <Image
                  src={ProfilePic}
                  alt="Profile Pic"
                  className="wallet-pic"
                />
                <h4 className="mb-0 fw-semibold text-dark d-flex align-items-center px-2">
                  {/* <small>
                    <i className="fa fa-rupee pe-1 rupee"></i>
                  </small> 
                  0.00
                </h4>
              </div> */}
              <LinkContainer
                to={"/User/Messages"}
                className="me-2 notification"
              >
                <Nav.Link>
                  <Image src={NotificationIcon} alt="" className="nav-icon" />
                  <Badge bg="warning" className="notif">
                    0
                  </Badge>
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to={"/User/Myprofile"}>
                <Nav.Link>
                  <Image src={UserIcon} alt="" className="pic-icon" />{" "}
                  {/* <b>User name</b> */}
                </Nav.Link>
              </LinkContainer>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Row className="mx-0 bg-dash">
        <Col md={2} className="bg-dark-subtle sidebar">
          <div className="px-3 pt-3 pb-2 profile">
            <div className="d-flex justify-content-between align-items-center pb-1">
              <Image
                src={ProfilePic}
                alt="Profile Pic"
                className="wallet-pic"
              />
              <div>
                {/* <p className="mb-0 balance">Balance</p> */}
                <h4 className="mb-0 fw-bold text-dark d-flex align-items-center">
                  <small>
                    <i className="fa fa-rupee pe-1 rupee"></i>
                  </small>
                  0.00
                </h4>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center wallet pb-1">
              <Button
                variant="primary"
                size="sm"
                className=""
                onClick={handleTransShow}
              >
                Transactions
              </Button>
              <Button
                variant="danger"
                size="sm"
                className=""
                onClick={handleShow}
              >
                Redeem
              </Button>
            </div>
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <i className="fa fa-bank pe-1"></i>Enter withdrawal details
                  <span className="d-block withdraw">
                    Minimum withdrawal amount is 500
                  </span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <i className="fa fa-bank"></i>
                    </InputGroup.Text>
                    <Form.Control
                      placeholder="Bank account number"
                      aria-label="bankno"
                      aria-describedby="basic-addon1"
                    />
                  </InputGroup>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon2">
                      <i className="fa fa-bank"></i>
                    </InputGroup.Text>
                    <Form.Control
                      placeholder="Re-enter bank account number"
                      aria-label="rebankno"
                      aria-describedby="basic-addon2"
                    />
                  </InputGroup>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon3">
                      <i className="fas fa-code-branch"></i>
                    </InputGroup.Text>
                    <Form.Control
                      placeholder="IFSC code"
                      aria-label="ifsc"
                      aria-describedby="basic-addon3"
                    />
                  </InputGroup>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon4">
                      <i className="fas fa-user-tie"></i>
                    </InputGroup.Text>
                    <Form.Control
                      placeholder="Bank account holder's name"
                      aria-label="accountuser"
                      aria-describedby="basic-addon4"
                    />
                  </InputGroup>
                  <p className="note">
                    <b>Note:</b> Amount will be credited within 2-3 business
                    days. Bank holidays aren’t counted as working days.
                  </p>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="primary"
                  className="w-100"
                  onClick={handleClose}
                >
                  Confirm Withdraw
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal size="lg" show={transShow} onHide={handleTransClose}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <i className="fas fa-history pe-1"></i>Transactions history
                  {/* <span className="d-block withdraw">
                    Minimum withdrawal amount is 500
                  </span> */}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Table responsive striped>
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Details</th>
                      <th>Credit</th>
                      <th>Debit</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Referral</td>
                      <td>500</td>
                      <td>0</td>
                      <td>23/12/2023, 11:30:11 am</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Bank Withdrawal</td>
                      <td>0</td>
                      <td>500</td>
                      <td>23/12/2023, 12:08:11 pm</td>
                    </tr>
                  </tbody>
                </Table>
              </Modal.Body>
            </Modal>
          </div>
          <Nav
            defaultActiveKey="/User/Dashboard"
            className="flex-column py-2 side-nav"
          >
            <LinkContainer to={"/User/Dashboard"}>
              <Nav.Link>
                <i className="fa fa-dashboard"></i> Dashboard
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to={"/User/Mycertificates"}>
              <Nav.Link eventKey="link-1">
                <i className="fas fa-award"></i> My Certificates
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to={"/User/AllProjects"}>
              <Nav.Link eventKey="link-1">
                <i className="fas fa-project-diagram"></i> My Projects
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to={"/User/Myinternships"}>
              <Nav.Link eventKey="link-2">
                <i className="fas fa-user-graduate"></i> My Internships
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to={"/User/Mycourses"}>
              <Nav.Link eventKey="link-2">
                <i className="fas fa-book-reader"></i> My Courses
              </Nav.Link>
            </LinkContainer>

            <LinkContainer to={"/User/Myreferrals"}>
              <Nav.Link>
                <i className="fas fa-user-friends"></i> My Referrals
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to={"/User/Mysubscription"}>
              <Nav.Link eventKey="link-2">
                <i className="fas fa-money-check"></i> My Subscription
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to={"/User/Quiz"}>
              <Nav.Link eventKey="link-2">
                <i className="fas fa-question-circle"></i> Take Quiz
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to={"/User/Myprofile"}>
              <Nav.Link eventKey="link-2">
                <i className="fas fa-user-edit"></i> My Profile
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to={"/User/Messages"}>
              <Nav.Link eventKey="link-2">
                <i className="fas fa-comment-alt"></i> Messages
              </Nav.Link>
            </LinkContainer>

            <Nav.Link
              onClick={() => {
                dispatch(logout());
                navigate("/");
              }}
              eventKey="link-2"
              className="fixed-bottom px-4 mx-1 text-danger "
            >
              <i className="fas fa-sign-out-alt"></i> Log out
            </Nav.Link>
          </Nav>
        </Col>
        <Col className="">
          {loggedin ? <Outlet /> : <Navigate to={"/Signin"} />}
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
