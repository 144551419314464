import React, { useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  InputGroup,
  Row,
} from "react-bootstrap";
import LoginImg from "../assets/goals.png";
import User from "../assets/icons/user.png";
import Password from "../assets/icons/password.png";
import ProfileIcon from "../assets/icons/sign-up.png";
import Mobile from "../assets/icons/smartphone.png";
import Gmail from "../assets/icons/email.png";
import ReferralImg from "../assets/icons/delegate1.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userSignup } from "../store/UserSlice";

const Signup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [user, setUser] = useState({
    name: "",
    phone: "",
    email: "",
    password: "",
  });
  let [msg, setMsg] = useState("");
  const userSignupHandler = (e) => {
    e.preventDefault();
    dispatch(userSignup(user)).then((result) => {
      if (result.payload.user) {
        setMsg(result.payload.message);
        setTimeout(() => {
          navigate("/Signin");
        }, 2000);
      } else {
        setMsg(result.payload.message);
      }
    });
  };
  return (
    <>
      <div className="bg-signin">
        <Row className="mx-0">
          <Col
            md={7}
            className="position-absolute top-50 start-50 translate-middle"
          >
            <Row className="shadow-lg">
              <Col md={7} className="rounded-start-4 bg-white p-5">
                <div className="text-center pb-0">
                  <Image src={ProfileIcon} alt="Profile" className="pro-pic" />
                </div>
                <h4 className="text-center pb-2">Create Account</h4>
                <div className="text-center">
                  <span className="text-success fw-semibold">{msg}</span>
                </div>
                <Form className="px-5 py-3" onSubmit={userSignupHandler}>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <Image src={User} alt="User" className="sign-icon" />
                    </InputGroup.Text>
                    <Form.Control
                      placeholder="Name"
                      aria-label="Name"
                      aria-describedby="basic-addon1"
                      name="name"
                      value={user.name}
                      required
                      onChange={(e) =>
                        setUser({ ...user, name: e.target.value })
                      }
                    />
                  </InputGroup>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="mobile">
                      <Image src={Mobile} alt="User" className="sign-icon" />
                    </InputGroup.Text>
                    <Form.Control
                      placeholder="Mobile"
                      aria-label="Mobile"
                      aria-describedby="mobile"
                      name="phone"
                      value={user.phone}
                      required
                      onChange={(e) =>
                        setUser({ ...user, phone: e.target.value })
                      }
                    />
                  </InputGroup>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="gmail">
                      <Image src={Gmail} alt="User" className="sign-icon" />
                    </InputGroup.Text>
                    <Form.Control
                      placeholder="Email id"
                      aria-label="Email"
                      aria-describedby="gmail"
                      name="email"
                      value={user.email}
                      required
                      onChange={(e) =>
                        setUser({ ...user, email: e.target.value })
                      }
                    />
                  </InputGroup>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon2">
                      <Image src={Password} alt="User" className="sign-icon" />
                    </InputGroup.Text>
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      aria-label="Password"
                      aria-describedby="basic-addon2"
                      name="password"
                      value={user.password}
                      required
                      onChange={(e) =>
                        setUser({ ...user, password: e.target.value })
                      }
                    />
                  </InputGroup>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon2">
                      <Image
                        src={ReferralImg}
                        alt="User"
                        className="sign-icon"
                      />
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder="Referral code (optional)"
                      aria-label="referral"
                      aria-describedby="basicreferral"
                      name="referral"
                    />
                  </InputGroup>
                  <Button variant="success" type="submit" className="w-100">
                    Sign Up
                  </Button>
                  {/* <div className="mt-3 text-center">
                    <Link to={""}>Forgot your password?</Link>
                  </div> */}
                </Form>
              </Col>
              <Col md={5} className="bg-sign rounded-end-4">
                <Image
                  src={LoginImg}
                  alt="Login"
                  className="img-fluid opacity-25"
                />
                <div className="text-center sign-text">
                  <h5 className="text-white">
                    Welcome to{" "}
                    <span className="text-warning">VD Talent hub</span>
                  </h5>
                  <p className="text-white-50">
                    Signing up with Talent Hub opens doors to a world of
                    opportunities and connections.
                  </p>
                </div>
                <div className="text-center mt-5 pt-5">
                  <Link to={"/Signin"}>
                    <Button variant="outline-warning" className="px-5">
                      Sign In
                    </Button>
                  </Link>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Signup;
