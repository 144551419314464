import {
  Button,
  Col,
  Container,
  Form,
  Image,
  Nav,
  NavDropdown,
  Navbar,
  Offcanvas,
  Row,
} from "react-bootstrap";
import Logo from "./assets/talenthub.png";
import Slider from "./components/Slider";
import Skills from "./components/Skills";
import Footer from "./components/Footer";
import { Outlet } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";

function App() {
  return (
    <>
      <Container fluid>
        <Navbar expand="lg" className="py-0">
          <Container fluid>
            <Navbar.Brand href="#">
              <Image src={Logo} alt="Talent hub" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-lg`}
              aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
                  Offcanvas
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <LinkContainer to={"/"}>
                    <Nav.Link className="fw-bold">Home</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to={"/Aboutus"}>
                    <Nav.Link className="fw-bold">About Us</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to={"/Services"}>
                    <Nav.Link className="fw-bold">Services</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to={"/Internships"}>
                    <Nav.Link className="fw-bold">Internships</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to={"/Projects"}>
                    <Nav.Link className="fw-bold">Projects</Nav.Link>
                  </LinkContainer>
                  {/* <NavDropdown
                    title="Dropdown"
                    id={`offcanvasNavbarDropdown-expand-lg`}
                  >
                    <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
                    <NavDropdown.Item href="#action4">
                      Another action
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="#action5">
                      Something else here
                    </NavDropdown.Item>
                  </NavDropdown> */}
                  <LinkContainer to={"/Career"}>
                    <Nav.Link className="fw-bold">Career</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to={"/Contact"}>
                    <Nav.Link className="fw-bold">Contact Us</Nav.Link>
                  </LinkContainer>
                </Nav>
                <div className="d-flex">
                  <LinkContainer to={"/Signin"}>
                    <Button variant="warning" className="px-3 fw-bold mx-1">
                      Sign In
                    </Button>
                  </LinkContainer>
                  <LinkContainer to={"/Signup"}>
                    <Button variant="danger" className="px-3 fw-bold mx-1">
                      Sign Up
                    </Button>
                  </LinkContainer>
                </div>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      </Container>
      <Outlet />
      <Footer />
    </>
  );
}

export default App;
