import React from "react";
import AboutImg from "../assets/about-us.png";
import AboutusImg from "../assets/aboutus.jpg";
import { Col, Container, Image, Row } from "react-bootstrap";

const Aboutus = () => {
  return (
    <>
      <Row className="mx-0 px-0">
        <Col className="px-0">
          <Image src={AboutImg} className="img-fluid" />
        </Col>
      </Row>
      <Row className="mx-0 py-4 px-5">
        <Col md={7} className="">
          <small className="fw-semibold text-danger">ABOUT US</small>
          <h4 className="fw-bold">The Company</h4>
          <p>
            Over the past years, VD Talent hub professionals have come together
            for our clients, communities, and each other to face the substantial
            challenges of the global pandemic. We continue to be deeply grateful
            and committed to helping our people thrive as we experience
            significant growth in our business and transition to a hybrid future
            of work.
          </p>
          <p>
            VD Talent Hub is a forward-thinking, innovative company specializing
            in connecting exceptional talent with leading organizations across
            various industries. We serve as a bridge between talented
            professionals seeking fulfilling opportunities and companies
            striving to build high-performing teams.
          </p>
          <p>
            We pride ourselves on our commitment to integrity, transparency, and
            excellence in service. Our team comprises dedicated experts who are
            passionate about unlocking potential and fostering success for both
            individuals and businesses. Whether it's permanent placements,
            contract roles, or project-based staffing, we offer tailored
            solutions to meet the unique needs of our clients. we aim to
            continually evolve and set new standards in the realm of talent
            acquisition and management.
          </p>
          <p>
            Ultimately, at Talent Hub, we believe in the transformative power of
            exceptional talent and its impact on driving organizational success
            and individual career growth.
          </p>
        </Col>
        <Col md={5}>
          <Image src={AboutusImg} className="img-fluid rounded-2 border p-1" />
        </Col>
      </Row>
      <Row className="mx-0 py-3"></Row>
    </>
  );
};

export default Aboutus;
