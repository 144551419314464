import React from "react";
import { Button, Col, Form, Image, Row } from "react-bootstrap";
import ContactImg from "../assets/conatct.png";
import ContactusImg from "../assets/contact1.png";

const Contact = () => {
  return (
    <>
      <Row className="mx-0 px-0 pb-2">
        <Col className="px-0">
          <Image src={ContactImg} className="img-fluid" />
        </Col>
      </Row>
      <Row className="mx-0 py-4 px-5">
        <Col md={5} className=" rounded-2 border p-2 bg-warning-subtle ">
          <h4 className="text-danger text-center px-4 py-5 fw-bold">
            We‘re Happy to Discuss Your Project and Answer any Question
          </h4>
          <Image src={ContactusImg} className="img-fluid p-4" />
        </Col>
        <Col md={7} className="px-5 py-5 border rounded-2 bg-secondary-subtle">
          <h3 className="fw-bold text-danger pb-4">
            Let‘s Start <br />
            The Conversation.
          </h3>
          <Form>
            <Row>
              <Col>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Name</Form.Label>
                  <Form.Control type="text" placeholder="Your name" />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Phone</Form.Label>
                  <Form.Control type="text" placeholder="Your phone" />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" placeholder="Your email" />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Subject</Form.Label>
                  <Form.Control type="text" placeholder="Subject" />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    placeholder="Your message type here..."
                  />
                </Form.Group>
              </Col>
            </Row>
            <Button variant="danger" className="w-100">
              Submit your query
            </Button>
          </Form>
        </Col>
      </Row>
      <Row className="mx-0 py-3"></Row>
    </>
  );
};

export default Contact;
