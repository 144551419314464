import React from "react";
import ProjectsImg from "../assets/projects.png";
import { Col, Image, Row } from "react-bootstrap";

const Projects = () => {
  return (
    <>
      <Row className="mx-0 px-0 pb-3">
        <Col className="px-0">
          <Image src={ProjectsImg} className="img-fluid" />
        </Col>
      </Row>
      <h3>Projects</h3>
    </>
  );
};

export default Projects;
