import React from "react";
import PrivacyImg from "../assets/privacy.jpg";
import { Col, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const Privacy = () => {
  return (
    <>
      <Row className="mx-0 px-0 pb-3">
        <Col className="px-0">
          <Image src={PrivacyImg} className="img-fluid" />
        </Col>
      </Row>
      <Row className="mx-0 px-5">
        <Col>
          <h4>PLEASE READ THE FOLLOWING CAREFULLY</h4>
          <p>
            We at VD Talent hub Private Limited (“VD Talent hub”, “VD - E”, “VD
            Solution” “we”, “us”) respect your privacy and want you to
            understand how we collect, use, and share data about you. This
            Privacy Policy covers our data collection practices and describes
            your rights to access, correct, or restrict our use of your personal
            data.
          </p>
          <p>
            Unless we link to a different policy or state otherwise, this
            Privacy Policy applies when you visit or use the VD Talent hub
            website, mobile applications, APIs or related services (the
            “Services”).
          </p>
          <p>
            This policy covers the VD Talent hub website and all the subdomains
            under vdsolution.in. Please refer to the following link to read our
            terms of service <Link to={"/"}>Terms of service</Link>.
          </p>
          <p>
            By using the Services, you agree to the terms of this Privacy
            Policy. You shouldn’t use the Services if you don’t agree with this
            Privacy Policy or any other agreement that governs your use of the
            Services.
          </p>
          <h5>Information Collection</h5>
          <p>
            We collect personal information, including but not limited to names,
            contact details, payment information, and demographic data, when
            users enroll in courses, apply for internships, or subscribe to our
            services.
          </p>
          <h5>Use of Information</h5>
          <p>
            The information collected is used for:
            <ul>
              <li>Enrolling participants in courses or internships</li>
              <li>
                Providing access to course materials or internship-related
                content
              </li>
              <li>Processing payments and managing subscriptions</li>
              <li>
                Communicating important updates, newsletters, or promotional
                materials
              </li>
              <li>Improving our services and user experience</li>
              <li>Complying with legal obligations</li>
            </ul>
          </p>
          <h5>Data Security</h5>
          <p>
            We employ industry-standard security measures to protect user data
            from unauthorized access, alteration, disclosure, or destruction.
            However, no method of transmission over the internet or electronic
            storage is completely secure; therefore, we cannot guarantee
            absolute security.
          </p>
          <h5>Data Sharing</h5>
          <p>
            We do not sell, trade, or rent users' personal information to third
            parties. However, we may share data with trusted service providers
            or partners who assist us in operating our website, conducting our
            business, or servicing users, provided that they agree to keep this
            information confidential.
          </p>
          <h5>Cookies and Tracking</h5>
          <p>
            We may use cookies or similar technologies to enhance user
            experience, track usage patterns, and gather information about
            users' interactions with our website. Users may choose to disable
            cookies in their web browser settings, although this may affect
            certain functionalities.
          </p>
          <h5>Third-Party Links</h5>
          <p>
            Links to external, or third-party websites, may be provided solely
            for your convenience. Such links from us to an external website does
            not imply or mean that VD Talent hub endorses or accepts any
            responsibility for the content or the use of such a website. VD
            Talent hub does not give any representation regarding the quality,
            safety, suitability, or reliability of any external websites or any
            of the content or materials contained in them. It is important for
            you to take necessary precautions, especially to ensure appropriate
            safety from viruses, worms, Trojan horses and other potentially
            destructive items.
          </p>
          <h5>Age Restrictions</h5>
          <p>
            You explicitly agree you are 18 years of age or older, unless
            represented by a parent or legal guardian, provided that where
            collection of any information becomes necessary for the performance
            of our contractual obligations, or when required under the concerned
            law, we shall notify you in a time-bound and appropriate manner,
            informing the purposes and reasons for such collection and seek your
            explicit consent, and where applicable, parental authorization,
            prior to the processing of such data. If VD Talent hub determines
            that it is in possession of any information belonging to an
            individual below 18 years of age which is submitted, collected or
            generated in breach of the terms of this Policy, it will delete the
            same without any notice to the individual to whom such information
            belongs to.
          </p>
          <h5>Update</h5>
          <p>
            We reserve the right to update or modify this Privacy Policy at any
            time. Users are encouraged to frequently check this page for any
            changes. By continuing to use our services after any modifications,
            users acknowledge and agree to the updated Privacy Policy.
          </p>
          <h5>Contact Information</h5>
          <p>
            If you have any questions about our policy or related dealings with
            us or would like further information about our services and
            practices, you can contact us at{" "}
            <span className="fw-bold">tech@vdsolution.in</span>.
          </p>
        </Col>
      </Row>
      <Row className="mx-0 py-3"></Row>
    </>
  );
};

export default Privacy;
