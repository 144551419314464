import React, { useState } from "react";
import {
  Alert,
  Button,
  Col,
  Form,
  Image,
  InputGroup,
  Row,
} from "react-bootstrap";
import ReferImg from "../assets/referral.png";
import ReferralImg from "../assets/referal1.png";
import ReferralImgTot from "../assets/icons/delegate.png";
import TotalEarningImg from "../assets/icons/income.png";
import { CopyToClipboard } from "react-copy-to-clipboard";

const MyReferrals = () => {
  const userDet = JSON.parse(localStorage.getItem("user"));
  const [copied, setCopied] = useState(false);
  return (
    <>
      <h4 className="pb-1 pt-3">My Referrals</h4>
      <Row className="mx-0 pt-0">
        <Col className="ps-0 pe-1">
          <Alert variant="light" className="shadow-sm">
            <Row>
              <Col>
                <Alert variant="warning">
                  <div className="d-flex justify-content-between align-items-center">
                    <Image
                      src={ReferralImgTot}
                      className="w-25 border rounded-circle p-1"
                    />
                    <div>
                      <h6 className="mb-0">Total Referrals</h6>
                      <h5 className="fw-bold text-center">0</h5>
                    </div>
                  </div>
                </Alert>
              </Col>
              <Col>
                <Alert variant="info">
                  <div className="d-flex justify-content-between align-items-center">
                    <Image
                      src={TotalEarningImg}
                      className="w-25 border rounded-circle p-1"
                    />
                    <div>
                      <h6 className="mb-0">Total Earnings</h6>
                      <h5 className="fw-bold text-center">0</h5>
                    </div>
                  </div>
                </Alert>
              </Col>
            </Row>
            <Row className="user-det">
              <Col>
                <p className="text-center mb-2">
                  <small className="fw-bold">REFER A FRIEND</small>
                </p>
                <p className="text-center mb-2 pb-3">
                  Refer your friends and earn upto 10% off when they
                  successfully joined paid courses. One sharing, more happy
                  people.
                </p>
                <p className="fw-semibold mb-1">Your referral code:</p>
                <Form>
                  <p className={copied ? "copy" : "d-none"}>Copied</p>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Referral code"
                      aria-label="Disabled input example"
                      value={userDet && userDet[0].user_id}
                      disabled
                      readOnly
                    />
                    <CopyToClipboard
                      text={userDet && userDet[0].user_id}
                      onCopy={() => setCopied(true)}
                    >
                      <Button variant="secondary" id="button-addon2">
                        <i className="fas fa-copy"></i>
                      </Button>
                    </CopyToClipboard>
                  </InputGroup>
                </Form>
                {/* <Form.Control
                  type="text"
                  placeholder="Referral code"
                  aria-label="Disabled input example"
                  value={userDet && userDet[0].user_id}
                  disabled
                  readOnly
                /> */}
                <Alert variant="warning" className="my-4">
                  <h6 className="small">
                    If you invite 20 friends & they joined paid courses. You
                    earn{" "}
                    <span className="fw-bold">
                      20 x <i className="fa fa-rupee earn"></i>250 ={" "}
                      <i className="fa fa-rupee earn"></i>5,000
                    </span>
                    .
                  </h6>
                </Alert>
                <p className="small terms">Terms & conditions apply.</p>
              </Col>
            </Row>
          </Alert>
        </Col>
        <Col md={7} className="ps-1 pe-0">
          <Alert variant="light" className="shadow-sm">
            <Row className="">
              <Col>
                <Image src={ReferImg} className="img-fluid" />
                <Image src={ReferralImg} className="img-fluid" />
              </Col>
            </Row>
          </Alert>
        </Col>
      </Row>
    </>
  );
};

export default MyReferrals;
