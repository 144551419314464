import React from "react";

const Shipping = () => {
  return (
    <div>
      <h4>Shipping & Delivery Policy</h4>
      <b>Last updated on Jan 2nd 2024</b>
      <p>Shipping is not applicable for business.</p>
    </div>
  );
};

export default Shipping;
