import React from "react";
import Footer from "../components/Footer";
import { Col, Image, Row } from "react-bootstrap";
import WebDesign from "../assets/webdesign.png";
import Training from "../assets/training.png";
import Internship from "../assets/internship.png";
import ServicesImg from "../assets/our-service-banner.jpg";

const Services = () => {
  return (
    <>
      <Row className="mx-0 px-0 pb-3">
        <Col className="px-0">
          <Image src={ServicesImg} className="img-fluid" />
        </Col>
      </Row>
      <Row className="mx-0 py-3 px-5 bg-services">
        <Col md={4}>
          <Image
            src={WebDesign}
            alt="Web Design"
            className="services-icon img-fluid"
          />
        </Col>
        <Col md={8}>
          <h5 className="fw-bold">Web Design/Development</h5>
          <p className="text-start">
            We specialize in bringing ideas to life through sleek design,
            intuitive user experiences, and robust functionality. Our team of
            experts merges artistic flair with technical expertise to build
            websites that not only look exceptional but also perform seamlessly
            across all devices.
          </p>
          <p>
            Our passion for innovation, attention to detail, and commitment to
            client satisfaction drive us to create engaging websites that stand
            out in today's competitive landscape. Partner with us, and let's
            collaborate to turn your digital aspirations into a compelling
            reality."
          </p>
        </Col>
      </Row>
      <Row className="mx-0 py-3 px-5 bg-services">
        <Col md={8}>
          <h5 className="fw-bold">Online Training</h5>
          <p className="text-start">
            We understand the importance of flexible learning in today's
            fast-paced world. Our platform provides a rich ecosystem where
            learners can explore a vast array of topics, from technical skills
            to personal development, through interactive modules, videos and
            expert-led content.
          </p>
          <p>
            With a focus on user-centric design and curated content from
            industry experts, we're committed to delivering quality education
            that fits your schedule and learning style. Join us on a journey of
            knowledge discovery and growth as we empower you to unlock your true
            potential, one course at a time."
          </p>
        </Col>
        <Col md={4}>
          <Image
            src={Training}
            alt="Web Design"
            className="services-icon img-fluid"
          />
        </Col>
      </Row>
      <Row className="mx-0 py-3 px-5 bg-services">
        <Col md={4}>
          <Image
            src={Internship}
            alt="Web Design"
            className="services-icon img-fluid"
          />
        </Col>
        <Col md={8}>
          <h5 className="fw-bold">Internships</h5>
          <p className="text-start">
            Our internship program is designed to provide aspiring individuals
            with a hands-on, immersive experience that serves as a launchpad for
            their careers. Our internships are crafted to offer a dynamic
            environment where learning meets practical application.
          </p>
          <p>
            Whether you're a student eager to explore different career paths or
            seeking to apply classroom knowledge to real-world scenarios, our
            internship program offers exposure to diverse fields, mentorship
            opportunities, and a chance to make a tangible impact.
          </p>
        </Col>
      </Row>
      <Row className="mx-0 py-3"></Row>
    </>
  );
};

export default Services;
