import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import TermsofuseImg from "../assets/Terms-Conditions.jpg";
import { Link } from "react-router-dom";

const TermsofUse = () => {
  return (
    <>
      <Row className="mx-0 px-0 pb-3">
        <Col className="px-0">
          <Image src={TermsofuseImg} className="img-fluid" />
        </Col>
      </Row>
      <Row className="mx-0 px-5">
        <Col>
          <h4>PLEASE READ THE FOLLOWING CAREFULLY</h4>
          <p>
            These terms and conditions (“Terms and Conditions”) control your use
            of this website vdsolution.in (“Website”). In these Terms and
            Conditions, “VD Talent hub” is referred to as the “Company”, “us,”
            or “we.”
          </p>
          <p>
            ‘You’ refers as user or a paying customer. If you are a company or
            another person who gives access to company products, you agree to
            take responsibility in full in case of damages or indemnification
            that could properly lie against the customer.
          </p>
          <p>
            The VD Talent hub website (the ‘Site’), the educational services
            made available through the site and the content (the ‘Products’) are
            owned, operated and maintained, as applicable, by VD Talent hub
            (‘we’, ‘our’, ‘us’, or the ‘Company’). The Site, Products and
            Content are, collectively, the ‘Company Products’.
            <br />
            By (a) using or accessing the Company Products, including, but not
            limited to downloading or accessing, (b) offering a Course through
            the Site or through Software; you agree to the terms and conditions
            set forth in these Terms of Use (the “Terms”)
          </p>
          <p>
            BY USING THIS WEBSITE OR ITS PRODUCTS AND SERVICES, YOU AGREE AND
            WARRANT THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND BY
            THESE TERMS. COMPANY’S PRIVACY POLICY CAN BE FOUND AT
            <Link to={"/Privacy-policy"}> ‘Privacy Policy’</Link>. IF YOU DO NOT
            ACCEPT THESE TERMS, YOU MUST NOT USE – AND ARE NOT AUTHORIZED TO USE
            – ALL OR ANY PORTION OF THE COMPANY’S WEBSITE AND ITS PRODUCTS OR
            SERVICES (AS DEFINED BELOW).
          </p>
          <h5>
            PLEASE READ THEM CAREFULLY BEFORE YOU USE THE SERVICES OF THIS SITE.
          </h5>
          <p>
            <ul>
              <li>
                You should not use this site in an unlawful manner; you must
                respect website terms and conditions and follow the privacy
                policy.
              </li>
              <li>
                Under no situations or circumstances, the company will be liable
                for any change in the content which it provides on the website
                through its products and services, including but not limited to
                any errors, omissions, loss or damage experienced in connection
                with the use of exposure, any content made available via our
                products, services or various resources such as email, blog etc.
              </li>
              <li>
                Our services are free to any user with access to the internet.
                However, we are not responsible for the charges incurred for the
                usage of hardware, software or internet services provider fee.
                Also, the user is fully responsible for the proper functioning
                of computer hardware and internet access
              </li>
              <li>
                You will be required to use login credentials for some of the
                sections on the site and the company reserves the right to block
                access to our services for any user who does not follow these
                conditions
              </li>
              <li>
                We make sure that users get uninterrupted access to our service,
                but there is no obligation to do so.
              </li>
              <li>
                VD Talent hub is not responsible and is not obligated for issues
                in your network or server beyond certain limits.
              </li>
            </ul>
          </p>
          <h5>Online Training & Internships</h5>
          <p>
            Participants must enroll in courses by completing the registration
            process and paying the specified fees. The payment terms, including
            refunds and cancellation policies, will be communicated upon
            enrollment.
          </p>
          <p>
            Participants applying for internships must follow the application
            process and pay any specified fees. Refunds, if available, will be
            subject to the internship cancellation policy outlined upon
            application.
          </p>
          <p>
            Refunds for online training courses and internships will be provided
            according to the refund policy specified for each course and
            internship. Participants must adhere to the cancellation deadlines
            to qualify for a refund.
          </p>
          <h5>Subscription Access</h5>
          <p>
            Subscribers agree to the terms and fees specified for access to
            subscription-based services. Subscription payments are subject to
            the terms mentioned in the subscription plan, including renewal,
            cancellation, and refunds if applicable.
          </p>
          <h5>Referral Program</h5>
          <p>
            Participants in the referral program agree to abide by the program
            rules and guidelines provided by VD Talent hub. Referrers may
            receive rewards or benefits according to the terms of the referral
            program for successful referrals.
          </p>
          <p>
            <span className="fw-bold">Referral Rewards:</span> Rewards, if
            offered, will be granted as specified in the referral program
            guidelines. Referrers are eligible for rewards only if the referred
            individual completes the specified action or meets the criteria
            outlined in the referral program.
          </p>
          <h5>General Terms</h5>
          <h6>Intellectual Property</h6>
          <p>
            All materials, including training content, resources, and
            subscription-based content provided by VD Talent hub, are protected
            by intellectual property laws and remain the property of the
            company.
          </p>
          <h6>Data Privacy</h6>
          <p>
            Vd Talent hub respects user privacy and handles personal data in
            accordance with its Privacy Policy. By engaging with our services,
            users consent to the collection and use of their data as outlined in
            the Privacy Policy.
          </p>
          <h6>Liability</h6>
          <p>
            Vd Talent hub shall not be liable for any direct, indirect,
            incidental, consequential, or special damages arising from or
            related to the use of any of the services, except in cases of gross
            negligence or willful misconduct.
          </p>
          <h6>Term and Termination</h6>
          <p>
            We reserve the right to block your access to the Content and
            Courseware with immediate effect as a result of your
            misrepresentation, default, misconduct, or breach of your
            obligations under this Agreement (“Event of Default“). On the
            occurrence of any Event of Default, we shall be authorized to
            exercise all the rights and remedies under this Agreement or
            applicable Law or available in equity to seek indemnification for
            any Loss or Claim resulting from any such Event of Default.
          </p>
          <h5>Governing Law and Dispute Resolution</h5>
          <h6>Jurisdiction</h6>
          <p>
            These Terms shall be governed by and construed in accordance with
            the laws of India and the courts in Hyderabad. Any disputes arising
            from these Terms shall be subject to the exclusive jurisdiction of
            the courts in Hyderabad.
          </p>
          <h5>Contact Information</h5>
          <p>
            For inquiries or concerns regarding these terms and conditions or
            our services, please contact us at support@vdsolution.in.
          </p>
        </Col>
      </Row>
      <Row className="mx-0 py-3"></Row>
    </>
  );
};

export default TermsofUse;
