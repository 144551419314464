import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import Gmail from "../assets/icons/gmail.png";
import Phone from "../assets/icons/customer-service.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div className="pt-5">
        <div className="bg1">
          <Row className="px-0 mx-0"></Row>
        </div>
        <div className="bg-footer text-white py-4">
          <Container fluid className="px-4">
            <Row className="mx-0">
              <Col md={4}>
                <h5 className="text-warning">About Us</h5>
                <p>
                  VD Talent Hub is a forward-thinking, innovative company
                  specializing in connecting exceptional talent with leading
                  organizations across various industries. We serve as a bridge
                  between talented professionals seeking fulfilling
                  opportunities and companies striving to build high-performing
                  teams.
                </p>
              </Col>
              <Col md={4}>
                <h5 className="text-warning">Quick Links</h5>
                <ul>
                  <li>
                    <Link to="/Projects">Projects</Link>
                  </li>
                  <li>
                    <Link to="/Internships">Internships</Link>
                  </li>
                  <li>
                    <Link to="/Aboutus">About Us</Link>
                  </li>
                  <li>
                    <Link to="/Terms-of-use">Terms & Conditions</Link>
                  </li>
                  <li>
                    <Link to="/Privacy-policy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/Refund-policy">Refund Policy</Link>
                  </li>
                </ul>
              </Col>
              <Col md={4}>
                <h5 className="text-warning">Contact Us</h5>
                <small className="d-flex align-items-center">
                  <Image src={Phone} alt="Email" className="foot-icon" />
                  Contact us at
                </small>
                <p>040 3564 7536</p>
                <small className="d-flex align-items-center">
                  <Image src={Gmail} alt="Email" className="foot-icon" />
                  Write to us at
                </small>
                <p>contact@vdtalenthub.com</p>
                {/* <p className="d-flex align-items-center">
                <Image src={Whatsapp} alt="Email" className="foot-icon" />
                9xxxx xxxxx
              </p> */}
              </Col>
            </Row>
          </Container>
        </div>
        <div className="bg-dark py-2 text-white text-center">
          <small>© 2023 - VD Talent hub. All Rights Reserved.</small>
        </div>
      </div>
    </>
  );
};

export default Footer;
