import React from "react";
import CareerImg from "../assets/careers.jpg";
import { Col, Image, Row } from "react-bootstrap";

const Career = () => {
  return (
    <>
      <Row className="mx-0 px-0 pb-3">
        <Col className="px-0">
          <Image src={CareerImg} className="img-fluid" />
        </Col>
      </Row>
      <h3>Career</h3>
    </>
  );
};

export default Career;
